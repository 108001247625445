import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "site-content" }
const _hoisted_2 = { class: "section-content" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "single-room-content" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-md-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_page_header, {
      image: "window",
      title: "Jobs"
    }),
    _createVNode("main", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode("div", _hoisted_3, [
          _createVNode("div", _hoisted_4, [
            _createVNode("div", _hoisted_5, [
              _createVNode("div", _hoisted_6, [
                (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.jobs, (job, i) => {
                  return (_openBlock(), _createBlock("div", {
                    key: i,
                    class: "border border-danger p-2 mb-2"
                  }, [
                    _createVNode("h2", null, _toDisplayString(job.title) + " - " + _toDisplayString(job.location[0].toUpperCase() + job.location.slice(1)), 1),
                    (job.visible)
                      ? (_openBlock(), _createBlock("div", {
                          key: 0,
                          innerHTML: job.description
                        }, null, 8, ["innerHTML"]))
                      : _createCommentVNode("", true),
                    (!job.visible)
                      ? (_openBlock(), _createBlock("div", {
                          key: 1,
                          innerHTML: job.blurb
                        }, null, 8, ["innerHTML"]))
                      : _createCommentVNode("", true),
                    (!job.visible)
                      ? (_openBlock(), _createBlock("button", {
                          key: 2,
                          class: "btn btn-red",
                          onClick: ($event: any) => (_ctx.toggleDetails(job))
                        }, "See More Details", 8, ["onClick"]))
                      : _createCommentVNode("", true),
                    (job.visible)
                      ? (_openBlock(), _createBlock("button", {
                          key: 3,
                          class: "btn btn-red",
                          onClick: ($event: any) => (_ctx.toggleDetails(job))
                        }, "Hide Details", 8, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}