
import { ParsedItem, getJobs } from '../lib/Contentful';
import { defineComponent } from 'vue';
import PageHeader from '../components/PageHeader.vue'

interface JobsData {
  jobs: ParsedItem[];
}

export default defineComponent({
  name: 'Jobs',
  data(): JobsData {
    return {
      jobs: []
    }
  },
  async mounted() {
    await this.getJobs();
    const w = window as any;
    w.fbq('track', 'ViewContent');
  },
  methods: {
    async getJobs() {
      this.jobs = await getJobs()
    },
    toggleDetails(job: ParsedItem) {
      job.visible = !job.visible;
    }
  },
  components: {
    PageHeader
  }
});
